import React from 'react'
import MediaQuery from 'react-responsive'
import HubspotForm from 'react-hubspot-form'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Layout from '../components/layout'

import {
	Container,
	Section,
	Row,
	Column,
} from '../components/layouts'

import {
	Title,
	Subtitle,
	Paragraph,
	Button,
	Image,
	BetaLabel,
	Input,
	Card,
	List,
	Testimonial,
	Link,
	BtnField
} from '../components/ui'

import theme from '../theme/theme'
import { getUrlParams } from '../util'

import {requestCall} from '../factory'

const {
	introNonprofit,
	beOnline,
	forwardThinking,
	contactSales,
	testimonials,
	fundraising,
	intro,
	callToAction,
	retarget
} = theme.sections

const moneyFormat = (num) => {
	return num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.scrolledElement = null

		this.state = {
			animateCards: false,
			contactFormSubmitted: false,
			showSignUpModal: false,
			open: true,
			totalSupporters: 5000,
			downloads: 50,
			accounts: 20,
			totalPledges: ''
		}

		this.handleScroll = this.handleScroll.bind(this)
		this.smoothScroll = this.smoothScroll.bind(this)
		this.onOpenModal = this.onOpenModal.bind(this)
		this.onCloseModal = this.onCloseModal.bind(this)
		this.onFunnelChange = this.onFunnelChange.bind(this)
		this.requestCall = this.requestCall.bind(this)
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)

		const thankYouModal = getUrlParams().thankyou
		const GUID = getUrlParams().submissionGuid
		if (thankYouModal) {
			try {
				/* eslint-disable-next-line */
				analytics.track('Lead Form Submitted', {
					submissionGUID: GUID,
				})
			} catch (err) {
				// ignore
			}
		}

		const ebookModal = getUrlParams().ebook
		if (ebookModal) {
			try {
				/* eslint-disable-next-line */
				analytics.track('EBook Form Submitted', {
					submissionGUID: GUID,
				})
			} catch (err) {
				//
			}
		}

		setTimeout(function() {
			this.setState({ showSignUpModal: true })
		}.bind(this), 4000)
	}

	componentWillUnmount() {
		typeof window !== 'undefined' && window.removeEventListener('scroll', this.handleScroll)
	}

	onOpenModal() {
		this.setState({ open: true })
	}

	onCloseModal() {
		this.setState({ open: false })
	}

	onFunnelChange(e) {
		let val = e.target.value
		let name = e.target.name
		if (name.includes('_money')) {
			val = val.replace(/\D/g, '')
			val = val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
			val = `$${val}`
		}

		if(name.includes('phone')) {
			val = val.replace(/\D/g, '')
			val = val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
		}

		const idx = name.indexOf('_')
		if(idx > 0) {
			name = name.substring(0, idx)
		}

		this.setState({ [name]: val })
	}

	handleScroll() {
		if (document.getElementById('cardsContainer')) {
			this.scrolledElement = document.getElementById('cardsContainer')

			if ((this.scrolledElement.offsetTop - (window.innerHeight / 2)) < window.scrollY) {
				this.setState({
					animateCards: true
				})
			}
		}
	}

	smoothScroll(refTo) {
		const element = document.querySelector(refTo)
		element.scrollIntoView({
			behavior: 'smooth'
		})
	}

	requestCall() {
		this.props.requestCall(this.state)
	}

	render() {
		const totalUsers = Math.round(this.state.totalSupporters * this.state.downloads * this.state.accounts / 10000)

		const pledgeBuy = Math.round(this.state.totalSupporters)

		return (
			<Layout>
				{/* Intro section */}
				<Section id="intro" bg={introNonprofit.bg} paddingTop={'72px'} smallPaddingTop={'8em'} paddingBottom={'0'}>
					<Container>
						{/* Intro first section */}
						<Row marginBottom={'2em'} smallMarginBottom={'1em'}>
							<Column vAlign={'center'} hAlign={'left'} maxWidth={'50%'}>
								<Title fontSize={'50px'} style={{margin: 0}}>Flourish turns <br/> donation pledges <br/> into working capital.</Title>
								<Row>
									<Column row>
										<Button href={introNonprofit.content.firstPart.firstBtn.link} hoverBg={theme.colors.salomie} hoverTextColor={theme.colors.black} marginRight={'1.5em'} smallMarginRight={'1em'} smallMarginBottom={'1em'} maxWidth={'250px'}>{introNonprofit.content.firstPart.firstBtn.text}</Button>
									</Column>
								</Row>
							</Column>
							<Column maxWidth={'50%'}>
								<MediaQuery query={`(max-width: ${theme.breakPoints.small}) and (min-width: ${theme.breakPoints.xsmall})`}>
									<div>
										<br />
									</div>
								</MediaQuery>
								<MediaQuery minWidth={theme.breakPoints.xsmall}>
									<Image src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/WebHeader.png'} marginTop={'2em'} />
								</MediaQuery>
							</Column>
						</Row>
					</Container>
				</Section>
				<Section >
					<Section >
						<Container>
							<MediaQuery maxWidth={theme.breakPoints.small}>
								{/* Intro second section */}
								<Row marginBottom={'4em'}>
									<Column maxWidth={'50%'}>
										<Image src={introNonprofit.content.secondPart.image} />
									</Column>
									<Column maxWidth={'50%'}>
										<Title fontSize={'33px'} marginBottom={'1.5em'} marginLeft={'0'}>It's your money. Access it when you need it</Title>
										<List list={introNonprofit.content.secondPart.list} />
									</Column>
								</Row>
							</MediaQuery>
							<MediaQuery minWidth={theme.breakPoints.small}>
								{/* Intro second section */}
								<Title fontSize={'45px'}style={{width: '80%'}} marginLeft={'0'}>It's your money. Access it when you need it.</Title>
								<Row>
  								<Column center vAlign={'center'} maxWidth={'22.75%'} row>
  									<Input name="pledgeCount" onChange={this.onFunnelChange} placeholder="72" type="number" value={this.state.pledgeCount} minLength="2" shadow autoComplete="off" required="required" />
  									<Paragraph maxWidth={'100%'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={0} fontWeight={'400'}>Total # Pledges</Paragraph>
  								</Column>
  								<Column vAlign={'center'} maxWidth={'3%'} row>
  									<Paragraph fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>X</Paragraph>
  								</Column>
  								<Column vAlign={'center'} maxWidth={'22.75%'} row>
  									<Input name="totalPledges_money" money onChange={this.onFunnelChange} placeholder="$1,500" type="money" value={this.state.totalPledges} maxLength="8" shadow autoComplete="off" required="required" />
  									<Paragraph maxWidth={'100%'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={0} fontWeight={'400'}>Total $ Pledged</Paragraph>
  								</Column>
  							</Row>
								<Row>
									<Column vAlign={'center'} maxWidth={'22.75%'} row>
										<Input name="organization" onChange={this.onFunnelChange} placeholder="Girl Scouts" type="text" value={this.state.organization} shadow autoComplete="off" required="required" />
										<Paragraph maxWidth={'100%'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={0} fontWeight={'400'}>Your Organization Name</Paragraph>
									</Column>
									<Column vAlign={'center'} maxWidth={'3%'} row>
  									<Paragraph fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>X</Paragraph>
  								</Column>
  								<Column vAlign={'center'} maxWidth={'22.75%'} row>
  									<Input name="phone_phone" maxLength="12" onChange={this.onFunnelChange} placeholder="555-123-1000" type="phone" value={this.state.phone} shadow required="required" />
  									<Paragraph maxWidth={'100%'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={0} fontWeight={'400'}>Your Phone Number</Paragraph>
  								</Column>
  								<Column vAlign={'center'} maxWidth={'22.75%'} paddingTop={'1em'} row>
  									<BtnField onClick={this.requestCall}  hoverBg={theme.colors.salomie} hoverTextColor={theme.colors.black} marginRight={'1.5em'} smallMarginRight={'1em'} smallMarginBottom={'1em'} maxWidth={'250px'}>Request a call</BtnField>
  								</Column>
  							</Row>
							</MediaQuery>
						</Container>
					</Section>
				</Section>

				{/* Be online section */}
				<Section bg={beOnline.bg} paddingTop={'3em'} paddingBottom={'3em'}>
					<Container>
						<Row>
							<Column>
								<Title color={theme.colors.white} fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>Get approval within 72 hours</Title>
								<Paragraph color={theme.colors.white} maxWidth={'80%'} fontSize={'21px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2em'} fontWeight={'200'}>Speed is our prioirty because your priority is making the world a better place.</Paragraph>
    						<Row marginBottom={'4em'}>
    							<Column maxWidth={'33.3333333333%'} vAlign={'between'}>
    								<Image src={intro.content.secondPart.columns.first.image} marginBottom={'3em'} maxWidth={'234px'} maxHeight={"346px"} />
    								<div>
    									<Subtitle fontSize={'24px'} textAlign={'center'}>{intro.content.secondPart.columns.first.title}</Subtitle>
    									<Paragraph height={'78px'} maxWidth={'505px'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.2em'} fontWeight={'300'}>{intro.content.secondPart.columns.first.paragraph}</Paragraph>
    								</div>
    							</Column>
    							<Column maxWidth={'33.3333333333%'} vAlign={'between'}>
    								<Image src={intro.content.secondPart.columns.second.image} marginBottom={'3em'} maxWidth={'234px'}  maxHeight={"346px"} />
    								<div>
    									<Subtitle fontSize={'24px'} textAlign={'center'}>{intro.content.secondPart.columns.second.title}</Subtitle>
    									<Paragraph height={'78px'} maxWidth={'500px'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.2em'} fontWeight={'300'}>{intro.content.secondPart.columns.second.paragraph}</Paragraph>
    								</div>
    							</Column>
    							<Column maxWidth={'33.3333333333%'} vAlign={'between'}>
    								<Image src={intro.content.secondPart.columns.third.image} marginBottom={'3em'} maxWidth={'234px'}  maxHeight={"346px"} />
    								<div>
    									<Subtitle fontSize={'24px'} textAlign={'center'}>{intro.content.secondPart.columns.third.title}</Subtitle>
    									<Paragraph height={'78px'} maxWidth={'505px'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.2em'} fontWeight={'300'}>{intro.content.secondPart.columns.third.paragraph}</Paragraph>
    								</div>
    							</Column>
    						</Row>
							</Column>
						</Row>
					</Container>
				</Section>
				{/* Forward Thinking section */}
				<Section id="cardsContainer" bg={forwardThinking.bg} paddingTop={'3em'} paddingBottom={'3em'}>
					<Container>
						<Row>
							<Column>
								<Title color={theme.colors.white} fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>{forwardThinking.content.title}</Title>
								<Paragraph color={theme.colors.white} maxWidth={'420px'} fontSize={'21px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2.5em'} fontWeight={'400'}>{forwardThinking.content.paragraph}</Paragraph>
							</Column>
						</Row>
						<Row>
							<Column maxWidth={'50%'}>
								<Card style={{display: 'flex', padding: 20}} animatedLeft className={this.state.animateCards ? 'animate' : false}>
									<MediaQuery minWidth={theme.breakPoints.small}>
										<Row marginBottom={'0'} xsmallNoColumn>
											<Column vAlign={'center'} maxWidth={'30%'} smallMaxWidth={'25%'} xsmallMaxWidth={'25%'} style={{maxWidth: '30%'}}>
												<Image src={forwardThinking.features.first.icon} marginBottom={'0'} style={{maxWidth: 92, height: 'auto', width: 'auto'}} />
											</Column>
											<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'100%'} xsmallMaxWidth={'100%'}>
												<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{forwardThinking.features.first.title}</Subtitle>
												<Paragraph fontSize={'14px'}>{forwardThinking.features.first.paragraph}</Paragraph>
											</Column>
										</Row>
									</MediaQuery>
									<MediaQuery maxWidth={theme.breakPoints.small}>
										<Row marginBottom={'0'} xsmallNoColumn>
											<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'100%'} xsmallMaxWidth={'100%'}>
												<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{forwardThinking.features.first.title}</Subtitle>
												<Paragraph fontSize={'14px'}>{forwardThinking.features.first.paragraph}</Paragraph>
											</Column>
										</Row>
									</MediaQuery>

								</Card>
								<Card style={{display: 'flex', padding: 20}} animatedLeft className={this.state.animateCards ? 'animate' : false}>
									<MediaQuery minWidth={theme.breakPoints.small}>
										<Row marginBottom={'0'} xsmallNoColumn>
											<Column vAlign={'center'} maxWidth={'30%'} smallMaxWidth={'25%'} xsmallMaxWidth={'25%'} style={{maxWidth: '30%'}}>
												<Image width={'100%'} src={forwardThinking.features.second.icon} marginBottom={'0'} style={{maxWidth: 92, height: 'auto', width: 'auto'}} />
											</Column>
											<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'100%'} xsmallMaxWidth={'100%'}>
												<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{forwardThinking.features.second.title}</Subtitle>
												<Paragraph fontSize={'14px'}>{forwardThinking.features.second.paragraph}</Paragraph>
											</Column>
										</Row>
									</MediaQuery>
									<MediaQuery maxWidth={theme.breakPoints.small}>
										<Row marginBottom={'0'} xsmallNoColumn>
											<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'100%'} xsmallMaxWidth={'100%'}>
												<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{forwardThinking.features.second.title}</Subtitle>
												<Paragraph fontSize={'14px'}>{forwardThinking.features.second.paragraph}</Paragraph>
											</Column>
										</Row>
									</MediaQuery>
								</Card>
							</Column>
							<Column maxWidth={'50%'}>
								<Card style={{display: 'flex', padding: 20}} animatedRight className={this.state.animateCards ? 'animate' : false}>
									{forwardThinking.features.fourth.betaLabel && <BetaLabel src={forwardThinking.features.fourth.betaLabel} left={'auto'} right={'0'} top={'0'} marginLeft={'auto'} marginRight={'auto'} maxWidth={'70px'} />}
									<MediaQuery minWidth={theme.breakPoints.small} style={{flex: 1}}>
										<Row marginBottom={'0'} xsmallNoColumn>
											<Column vAlign={'center'} maxWidth={'30%'} smallMaxWidth={'25%'} xsmallMaxWidth={'25%'} style={{maxWidth: '30%', flex: 1}}>
												<Image width={'100%'} src={forwardThinking.features.third.icon} marginBottom={'0'} style={{maxWidth: 92, height: 'auto', width: 'auto'}} />
											</Column>
											<Column style={{display: 'flex', padding: 5, flex: 5}} vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'100%'} xsmallMaxWidth={'100%'}>
												<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{forwardThinking.features.third.title}</Subtitle>
												<Paragraph fontSize={'14px'}>{forwardThinking.features.third.paragraph}</Paragraph>
											</Column>
										</Row>
									</MediaQuery>
									<MediaQuery maxWidth={theme.breakPoints.small} style={{flex: 1}}>
										<Row marginBottom={'0'} xsmallNoColumn>
											<Column style={{display: 'flex', padding: 5, flex: 5}} vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'100%'} xsmallMaxWidth={'100%'}>
												<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{forwardThinking.features.third.title}</Subtitle>
												<Paragraph fontSize={'14px'}>{forwardThinking.features.third.paragraph}</Paragraph>
											</Column>
										</Row>
									</MediaQuery>
								</Card>
								<Card style={{display: 'flex', padding: 20}} animatedRight className={this.state.animateCards ? 'animate' : false}>
									<MediaQuery minWidth={theme.breakPoints.small}>
										<Row marginBottom={'0'} xsmallNoColumn>
											<Column vAlign={'center'} maxWidth={'30%'} smallMaxWidth={'25%'} xsmallMaxWidth={'25%'} style={{maxWidth: '30%'}}>
												<Image width={'100%'} src={forwardThinking.features.fourth.icon} marginBottom={'0'} style={{maxWidth: 92, height: 'auto', width: 'auto'}} />
											</Column>
											<Column style={{display: 'flex', padding: 5}}  vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'100%'} xsmallMaxWidth={'100%'}>
												<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{forwardThinking.features.fourth.title}</Subtitle>
												<Paragraph fontSize={'14px'}>{forwardThinking.features.fourth.paragraph}</Paragraph>
											</Column>
										</Row>
									</MediaQuery>
									<MediaQuery maxWidth={theme.breakPoints.small}>
										<Row marginBottom={'0'} xsmallNoColumn>
											<Column style={{display: 'flex', padding: 5}}  vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'100%'} xsmallMaxWidth={'100%'}>
												<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{forwardThinking.features.fourth.title}</Subtitle>
												<Paragraph fontSize={'14px'}>{forwardThinking.features.fourth.paragraph}</Paragraph>
											</Column>
										</Row>
									</MediaQuery>
								</Card>
							</Column>
						</Row>
					</Container>
				</Section>
				{/* testimonials */}
				<Section bg={testimonials.bg} paddingTop={'5em'} paddingBottom={'5em'} smallPaddingTop={'4em'} smallPaddingBottom={'3em'}>
					<Container>
						<Row>
							<Column>
								<Title color={theme.colors.white} fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>{testimonials.content.title}</Title>
								<Paragraph color={theme.colors.white} maxWidth={'80%'} fontSize={'21px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2em'} fontWeight={'200'}>{testimonials.content.paragraph}</Paragraph>
								<Row>
									{testimonials.content.quotes.map((test, i) =>
										(<Column key={i} vAlign={'center'} row>
											<Testimonial data={test}/>
										</Column>)
									)}
								</Row>
							</Column>
						</Row>
					</Container>
				</Section>
				{/* Flourish App */}
				<Section paddingTop={'5em'} paddingBottom={'5em'} smallPaddingTop={'4em'} smallPaddingBottom={'3em'}>
					<Container>
						<Row>
							<Column>
								<Title fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>{fundraising.content.title}</Title>
								<Paragraph maxWidth={'80%'} fontSize={'21px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2em'} fontWeight={'200'}>{fundraising.content.paragraph}</Paragraph>
								<Row>
									<Column style={{justifyContent: 'center'}}>
										<Title fontSize={'25px'} textAlign={'center'} marginBottom={'0.5em'}>{fundraising.content.listTitle}</Title>
										<List list={fundraising.content.list} />
										<MediaQuery minWidth={theme.breakPoints.small}>
											<Row marginBottom={'0'} style={{marginTop: 25}}>
												<Column center >
													<Link href={callToAction.content.appStore.link} maxWidth={'144px'} marginRight={'auto'}>
														<Image src={callToAction.content.appStore.image} marginRight={'auto'} marginBottom={'0'} height={'48px'} maxWidth={'144px'} />
													</Link>
												</Column>
												<Column center >
													<Link href={callToAction.content.googlePlay.link} maxWidth={'162px'} marginRight={'auto'}>
														<Image src={callToAction.content.googlePlay.image} marginBottom={'0'} height={'48px'} maxWidth={'162px'} />
													</Link>
												</Column>
											</Row>
										</MediaQuery>
									</Column>
									<Column>
										<Image src={intro.content.firstPart.image} marginTop={'2em'} />
									</Column>
								</Row>
								<MediaQuery maxWidth={theme.breakPoints.small}>
									<Row marginBottom={'0'} style={{jusifyContent: 'center', alignItems: 'center'}}>
										<Column width={'100%'} style={{jusifyContent: 'center', alignItems: 'center'}}>
											<Link href={callToAction.content.appStore.link} marginRight={'auto'} style={{jusifyContent: 'center', alignItems: 'center', padding: 'auto', display: 'flex', marginBottom: '1.5em'}}>
												<Image src={callToAction.content.appStore.image} marginBottom={'0'} height={'48px'} maxWidth={'144px'} smallWidth={'none'} style={{margin: 'auto'}}/>
											</Link>
											<Link href={callToAction.content.googlePlay.link} marginRight={'auto'} style={{jusifyContent: 'center', alignItems: 'center', padding: 'auto', display: 'flex', marginBottom: '1.5em'}}>
												<Image src={callToAction.content.googlePlay.image} marginBottom={'0'} height={'48px'} maxWidth={'162px'} style={{margin: 'auto'}}/>
											</Link>
										</Column>
									</Row>
								</MediaQuery>
							</Column>
						</Row>
					</Container>
				</Section>

				{/* Retarget */}
				<Section bg={retarget.bg} paddingTop={'5em'} paddingBottom={'5em'} smallPaddingTop={'4em'} smallPaddingBottom={'3em'}>
					<Container>
						<Row>
							<Column>
								<Title color={theme.colors.white} fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>{retarget.content.title}</Title>
								<Paragraph color={theme.colors.white} maxWidth={'80%'} fontSize={'21px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2em'} fontWeight={'200'}>{retarget.content.paragraph}</Paragraph>
								<Paragraph maxWidth={'100%'} fontSize={'25px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2em'} fontWeight={'200'}>{retarget.content.subtext}</Paragraph>
								<MediaQuery minWidth={theme.breakPoints.small}>
									<Image src={introNonprofit.content.secondPart.image2} style={{marginBottom: '6em'}}/>
								</MediaQuery>
								<Button href={introNonprofit.content.firstPart.firstBtn.link} style={{width: 300, margin: 'auto', marginBottom: '1em'}}>Contact us to learn more</Button>
							</Column>
						</Row>
					</Container>
				</Section>

				{/* Form */}
				<Section>
					<Row marginBottom={'0'}>
						<Column lateralPaddings={'0'} maxWidth={'50%'}>
							<MediaQuery minWidth={theme.breakPoints.small}>
								<img src={contactSales.content.leftBg}/>
							</MediaQuery>
						</Column>
						<Column lateralPaddings={'0'} >
							{!this.state.contactFormSubmitted ?
								<Title fontSize={'33px'} textAlign={'center'} marginTop={'1em'} maxWidth={'380px'}>{contactSales.content.title}</Title> :
								<Title fontSize={'33px'} textAlign={'center'} marginTop={'1em'} maxWidth={'380px'}>{contactSales.content.thankyouTitle}</Title>
							}

							{this.state.contactFormSubmitted &&
									<Paragraph fontSize={'16px'} textAlign={'center'} marginBottom={'1.5em'}>{contactSales.content.thankyouParagraph}</Paragraph>
							}
							<Row align={'center'} justify={'center'}>
								<Column maxWidth={'300px'} smallMaxWidth={'300px'} xsmallMaxWidth={'300px'}>
									<HubspotForm
										id="reactHubspotForm"
										portalId="4829666"
										formId="12707c0c-68a7-4746-aa0f-49f308472191"
										onSubmit={() => this.setState({ contactFormSubmitted: true })}
										loading={<div>{'Loading...'}</div>}
									/>
								</Column>
							</Row>
						</Column>
					</Row>
				</Section>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	error: PropTypes.any,
	success: PropTypes.bool,
	pending: PropTypes.bool,
	requestCall: PropTypes.func
}

const mapStateToProps = (state) => {
	const { utilState } = state
	return {
		error: utilState.error,
		pending: utilState.pending,
		success: utilState.success,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		requestCall: (data) => dispatch(requestCall(data)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(IndexPage)
